@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

main {
    min-height: 100vh;
}
.admin-bg{
    background-color: #f4f7fe;
    min-height: 100vh;
}

.filter-icon-wrapper {
    border: solid rgb(56, 142, 60) 1px;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-icon-wrapper .icon {
    font-size: 1.5rem;
    font-weight: 600;
    color: rgb(56, 142, 60);

}


.filter-menu {}

.filter-menu ul {
    list-style-type: none;

}

.filter-menu li {
    padding-left: 1rem;


}

.filter-menu li:hover {
    background-color: #88d19987;
}

.filter-menu .active {
    background-color: #88d19987;
}

.cat-img-preview {
    width: 30px;
    margin-right: 1rem;
}

.admin a {
    text-decoration: none;
    color: #16192c;
    font-size: .875rem !important;
    
    line-height: 1.5rem;
}

.bi {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bold;
}

.nav-logo {
    max-width: 137px;
    ;
}

.nav-left{
   
}

.dash-container {}

.sidebar-wrapper {

    z-index: 3;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
    width: 15rem;
font-family: 'Poppins' , sans-serif;
font-weight: 300 !important;


}

.page-content {}


.sidebar-logo {
    width: 120px;
}

.sidebar-list {
    align-items: stretch;

}

.topbar {
    width: calc(100% - (1.625rem * 2));
    margin: 2rem auto 0;
    border-radius: 10px;
    padding: 0 1.5rem;
    box-shadow: 0 0 .375rem .25rem rgba(161, 172, 184, .15);
    position: relative;
    padding-top: .25rem;
    padding-bottom: .2rem;
    height: 3.875rem;
    flex-wrap: nowrap;
    color: #697a8d;
    z-index: 2;
    background-color: #fff;
    -webkit-backdrop-filter: blur(6px);
background-color: rgba(255, 255, 255, 0.8);
transition-duration: 200ms;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-delay: 0ms;
transition-property: height;
}

.bi-list {
    font-size: 1.55rem !important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
}

.navbar-nav .nav-link {
    border-radius: .375rem;
    margin: 0rem 1rem;
    padding: .625rem 1rem;

}

.navbar-nav .nav-item {
   
}

.navbar-nav .nav-link {
    color: #4c4edd;
}

.nav-link:hover {
    /* background-color: rgb(0, 1, 42);
    
    color: white !important; */

}

.navbar-expand .navbar-nav {
    flex-direction: column !important;
}

@media (min-width:992px) {
    .navbar-vertical.navbar-expand-lg .navbar-nav {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;

        z-index: 3;
    }

    .sidebar-wrapper {
        height: 100vh;
        display: flex !important;
        flex-direction: column;
        
    }


    .sidebar-container {
        flex-direction: column;
        flex-grow: 1;
    }

    .right-section {
        flex: 1 !important;
        margin-left: 15rem;
        background-color: #f4f7fe;
        min-height: 100vh;


    }
}

.layout-menu-expanded .layout-overlay {
    display: none !important;
}

.navbar-nav {}
.layout-overlay{
}

@media (max-width: 992px) {
    .layout-overlay {
        display: block;
        position: absolute;
        top: 0px;
        right: 0;
        height: 100% !important;
        width: 100% !important;
        left: 0;
        background: #435971;
        opacity: 0.5;
        cursor: pointer;
        z-index: 2;
         background-color: #fff;
    -webkit-backdrop-filter: blur(6px);
background-color: rgba(58, 54, 54, 0.8);
transition-duration: 200ms;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-delay: 0ms;
transition-property: height;
    }

}
@media (max-width: 1200px) {
    

   

    .layout-menu {
        position: fixed !important;
        top: 0 !important;
        height: 100% !important;
        left: 0 !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        transform: translate3d(-100%, 0, 0);
        will-change: transform, -webkit-transform;
    }
}


.avatar {
    background-color: #88d199;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.dropdown-menu {
    border: none;
    box-shadow: 0 0 .375rem .25rem rgba(161, 172, 184, .15);
}

.navbar-brand {
    height: 64px;
    margin-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    


}



.img-preview-wrapper {
    width: 150px;
    height: 150px;
    border-radius: 2rem;
    background-color: #b5b5b6;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    object-fit: cover;
}

.img-container {
    position: relative;
    text-align: center;
}

.img-preview-wrapper:hover {
    border: 1px solid rgb(115, 230, 128);
    background-color: rgb(116, 212, 193);

}

.img-preview {
    object-fit: contain;
    height: 150px;
    width: 150px;
    z-index: 1;
    position: relative;
}

.alt-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

input::file-selector-button {}

.authentication-wrapper.authentication-basic {
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.authentication-wrapper {
    display: flex;
    flex-basis: 100%;
    min-height: 100vh;
    width: 100%;
}

.login-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #d9dee3;
    border-radius: .5rem;
    background-clip: padding-box;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, .12);
}

.authentication-wrapper.authentication-basic .authentication-inner .card .app-brand {
    margin-bottom: 2.5rem;
}

.authentication-wrapper.authentication-basic .authentication-inner {
    max-width: 400px;
    position: relative;
}

.authentication-wrapper .authentication-inner {
    width: 100%;
}

.login-body {
    background-color: #f5f5f9;
}

.btn-p {
    color: #fff;
    background-color: #696cff;
    border-color: #696cff;
    box-shadow: 0 .125rem .25rem 0 rgba(105, 108, 255, .4);
}

.dash-search {
    display: block;
    width: 100%;
    padding: .4375rem .875rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.53;
    color: #697a8d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d9dee3;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .375rem;
    box-shadow: none;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

/** admin dash  **/

.admin-dashboard {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.add-user-form {
    margin-top: 20px;
}

.admin .form-group {
    margin-bottom: 10px;
}

.admin label {
    display: block;
    font-weight: bold;
}

input[type='text'],
input[type='password'],
select {
    width: 100%;
    padding: 5px;
}
.form-control:focus, .form-control:focus + .input-group-text {
    border-color: #0a1c37; 
    outline: 0;
    box-shadow: none;
}

.input-group-text{
    cursor: pointer;
}





.table-nowrap th {
    font-size: 0.9rem;
}

.table-nowrap td {
    font-size: 0.8rem;
}

.table-toggle-wrapper {}

.table-toggle {

    display: flex;
    justify-content: center;
    align-items: center;

    align-self: start;

}

.table-toggle .bi {
    color: rgb(163, 120, 232);
    ;
}

.sidebar-container {
    display: flex;
    flex-flow: column;
    margin-top: 3rem;
    flex-grow: 1;
}
.sidebar-container ul li a {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    
}
.sidebar-container ul li .active {
    background-color: rgba(128, 227, 208, 0.154);
    
}
.sidebar-container ul li svg {
   color: rgba(40, 7, 89, 0.933);
    
}
.sidebar-container ul li a:hover {
    background-color: rgba(128, 227, 208, 0.154);
    
}
.sidebar-container ul li i {
    
    font-size: 18px;
}

.notfound {
    text-align: center;
}

.notfound h1 {
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type .5s alternate infinite;
}

.notfound .btn {
    color: #fff;
    background-color: var(--secondary-green);
    padding: 1rem 2rem;
}

@keyframes type {
    from {
        box-shadow: inset -3px 0px 0px #888;
    }

    to {
        box-shadow: inset -3px 0px 0px transparent;
    }
}

.btn-p {
    background-color: #696cff;
}

.btn-p:hover {
    background-color: #4c4edd;
    color: #fff;
}

/* Modal Styles */
.city-list {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.city-list .bi {
    color: #696cff;
    font-size: 1.5rem;
}

.city-list .modal-wrapper {
    background-color: #fff;
    border-radius: 0.5rem;
}

.city-list .modal-wrapper .modal-content {
    border: none;
    max-width: 80vw;
    max-height: 80vh;
    overflow-y: scroll;
}

.city-list .modal-wrapper .modal-content h4 {
    margin-top: 0;
}

.city-checkboxes {
    margin-top: 10px;
}

.city-checkboxes div {
    margin-bottom: 5px;
}

/* Add any additional styles as needed */

.admin .card {
    border: 0;
    box-shadow: 0 0 .175rem .125rem rgba(161, 172, 184, .12);
    ;
}

.admin .product-name {
    line-height: 1.2;
    font-size: 0.9rem;
}



.admin-product-image {
    border-radius: 0.6rem;
    object-fit: cover;
    background-color: #E1F0F8;
    box-shadow: 0px 0px 7px 0px #e1f4f8;

}

.admin-products {
    height: 100%;
}

.admin-products:hover {
    box-shadow: 0px 0px 6px 0px #b9c8f8c4;
    border-radius: 5px;

}

.admin-products .card-product {
    display: flex;
    flex-direction: column;
}
.customer-info span{
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #435971;
}
.customer-info a{
   color: #4c4edd;
}
.add-category-btn{
    font-size: 2rem;
    position:fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #585be1;
    color: #fff;
    bottom: 20px;
    right: 20px;
    outline: none;
    border: none;
    border-radius: 6px;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}

.image-container {
    transition: transform 0.3s; /* Smooth transition effect for scaling */
  }
  
  .image-container.enlarged {
    transform: scale(1.2); /* Scale the image up when it's enlarged */
    cursor: pointer; /* Change the cursor to indicate it can be clicked to close */
  }
  

  .sub-searchbar {
    background-color: #ffffff;
    border: 1px solid #e1e2e4;
    border-radius: .7rem;
    font-size: .8rem;
    position: relative;
    padding: .7rem 1rem .7rem 1rem;
    border-radius: 5px;
    font-weight: 400;
    line-height: 1;
    outline: none !important;
    /* Remove the blue outline */
    max-width: 400px;
  
  }
  .sub-searchbar-icon{
    background-color: #ffffff;
    border: 1px solid #e1e2e4 !important;
  }
  
