

:root {
  --li-grey: #373f50;
  --primary-red: #FF6363;
  --heading-grey: #566a7f;
  --primary-body: #697a8d;
  --secondary-body: rgb(74, 74, 74);
  --secondary-red: #FC292B;
  --primary-green: #66BFBF;
  --primary-orange: #FEB1A1;
  --secondary-green: #42D697;
  --primary-blue: #4FB7E7;
  --primary-blue-light: #E9F3FC;
  --primary-orange-light: #F7F2EE;
  --primary-green-light: #E6F6F3;
  --grey: rgb(246, 246, 247);
  --primary-purple: #C047EF;
  --secondary-orange: #fe7c0b;
}



/* #688DAC */
.text-pb {
  color: var(--primary-blue);
}

.text-pg {
  color: var(--primary-green);
}

.text-pr {
  color: var(--primary-red);
}

.text-or {
  color: var(--primary-orange);
}
.bg-p-pu{
  background-color: var(--primary-purple) !important;
} 
.bg-p-or{
  background-color: var(--secondary-orange) !important;
} 
/* html {
    font-size: 16px;
}
@media (min-width:768px) {
    font-size: 18px;
}
@media (min-width:1024px) {
    font-size: 19px;
}
@media (min-width:1280px) {
    font-size: 20px;
} */

@media (max-width:768px) {
  main{
    margin-bottom: 78px;
  }
}

body {
  font-family: 'PT Sans', sans-serif;
  line-height: 1.6;
  background: white;
  font-weight: 400;
  font-size: 1rem;
  color: var(--primary-body);

}


p {
  margin-bottom: 1rem;
  color: #646f79;
  color: var(--secondary-body);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  line-height: 1.3;
  color: var(--heading-grey);
}

h1 {
  margin-top: 0;
  font-size: calc(1.375rem + 1.5vw);
}

h2 {
  font-size: calc(1.325rem + .9vw);
}

h3 {
  font-size: calc(1.3rem + .6vw);
}

h4 {
  font-size: calc(1.275rem + .3vw);
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1.0625rem;

}

small,
.text-small {
  font-size: 0.875rem;
}

.text-sm {
  font-size: 0.75rem !important;
}

a {
  text-decoration: none;
  /* font-family: 'Poppins', 'Sans Serif'; */

}

.fw-100 {
  font-weight: 100;
}

.fw-300 {
  font-weight: 300;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.home-notification-wrapper{
  background-color: rgb(31,31,31);
  padding: 10px;
  display: flex;
  align-items: center;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  z-index: 999;
  font-size: 14px;
}
.btn-close-white{
  font-size: 12px;
  
}

.nav-wrapper {
  background-color: #fff;
  opacity: 1;
  transform: none;
  transition: opacity 0.5s ease, transform 0.5s ease; /* Add transition property */
position: relative;
z-index: 10;
  top: 0;
  width: 100%;
}
.fixed-nav{
  position: sticky;
  top: 0;
  box-shadow: 0 0px 5px rgba(0,0,0,.08);
  animation: fadeIn 0.5s ease; /* Apply the keyframe animation */
  opacity: 1; /* Ensure opacity is 1 when sticky */
  transform: none; /* Ensure no transform when sticky */
  
}

.fixed-nav.searchbar{
  width: 100%;
}

ul{
  padding: 0;
  margin: 0;
}
.nav .nav-li {
  font-family: 'Poppins', sans-serif;
  font-size: 14px !important;
  font-weight: 300 !important;
  padding-inline: 1rem;
  position: relative;
  
}
.nav .nav-li a{
  color: #165d59 !important;
}

.nav .nav-li .active::before{
  content: "";
background: #165d59;
height: 2px;

position: absolute;
left: 20px;
right: 20px;
top: auto;
bottom: -8px;
border-radius: 4px;
-webkit-border-radius: 4px;
-moz-border-radius: 4px;

}


.searchbar {
  background-color: #f3f5f9;
  border: none;
  border-radius: .7rem;
  font-size: .8rem;
  position: relative;
  padding: .7rem 1rem .7rem 1rem;
  border-radius: 5px;
  font-weight: 400;
  line-height: 1;
  outline: none !important;
  /* Remove the blue outline */

}


.clear-button {
  background-color: #f3f5f9;
}


li a {
  color: var(--li-grey) !important;
}

.navicon {
  font-size: 1.4rem;
}

.icon-wrapper {
  background-color: #f3f5f9 !important;
  position: relative;
  width: 2.875rem;
  height: 2.875rem;
  transition: color .25s ease-in-out;
  border-radius: 50%;
  line-height: 2.625rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4b566b;

}

.icon-wrapper .bi {
  font-size: 1.25rem;
  color: #4b566b
}


.nav-ul .nav-li .active {
  color: var(--primary-red) !important;

}
.nav-ul .nav-li :hover {
  color: var(--primary-red) !important;

}

.navbar-tool-label {
  position: absolute;
  top: -.3125rem;
  right: -.3125rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: var(--primary-red);
  color: #fff;
  font-size: .75rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.25rem;
}

.mobile-bottom-nav {
    display: flex;
    display: -webkit-flex;
    align-items: stretch;
    -webkit-align-items: stretch;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 78px;
    background: #fff;
    z-index: 4;
    padding: 8px 8px 12px;
    box-shadow: 0 0 10px rgba(0,0,0,.08);
}

.mobile-bottom-nav a {
  
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  font-size: 10px ;
  font-weight: 400;
  color: var(--primary-red)
}
.mobile-bottom-nav span{
  color: #373f50;
}
.mobile-bottom-nav .active span{
  font-size: 10px;
  font-weight: 600;
  color: var(--primary-red) !important;
}

.mobile-bottom-nav  .icon{
  background-color: rgb(252, 237, 242, 0);
  border-radius: 8px ;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 2px;
  color: var(--primary-red);
  
  transition: all .5s ease-in-out;
}
.mobile-bottom-nav .active .icon{
  background-color: rgb(252, 237, 242, 1);
}

.hero-wrapper {
  background-color: #F6FAFD;
  width: 100%;

}
.breadcrumb-item+.breadcrumb-item::before{
  color: var(--primary-green) !important;
}
.breadcrumb{
  font-size: 12px;
}

.breadcrumb-item .active {
  color: rgb(120, 144, 156) !important;
}
.breadcrumb-item a{
  color: var(--primary-green) !important;
}

.hero-slider {
  position: relative;
  width: 100%;

  overflow: hidden;


}


.slide-img {
  object-fit: cover;
  flex: 1;
  width: 100%;
  object-position: right bottom;
}

.swiper {

  border-radius: 1rem;
}

.swiper-slide {
  display: flex !important;
}

swiper-slide .p-1 .products .card-product .product-details-card .mb-1{
  min-height: 70px;
}
.banner-img {
  position: relative;
  overflow: hidden;

}

.banner-sm-container {
  scroll-behavior: smooth;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */


}


.banner-sm-container::-webkit-scrollbar {
  display: none !important;
  /* Safari and Chrome */
}

.banner-sm {

  overflow: hidden;



}

.card-text {}

.banner-sm1 {
  background-color: #E9F3FC;
  border-radius: 4px;
}

.products .card {
  border: none;
  height: 100%;
}

.products .card .card-body {
  padding: 0rem !important;
  display: flex;
  flex-direction: column;

}

.card-product {
  background-color: transparent;
  height: 100%;
  border: 0;
}

.product-image {
  border-radius: .6rem;

  object-fit: cover;
}

.products {
  border-radius: .8rem;
  height: 100%;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;

}
.product-details-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.products:hover {
  background-color: #fff;
  box-shadow: 2px 8px 40px rgba(0, 0, 0, .08);
  border-radius: .8rem;
}

.products .badge {

  background-color: rgb(66, 214, 151, 0.7);
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 300;

}
.products .offer-badge {

  background-color: rgba(255, 99, 99, 0.9);
  
 

}
.offer-badge{
  background-color: rgba(255, 99, 99, 0.9);
}
.out-of-stock-text{
  display: flex;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  line-height: 1.5rem;
  padding: .2rem;
  font-size: 12px;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
}


.add-to-cart-btn {
  margin-top: 5px;
  width: 100%;
  border-radius: 0.9rem;
  background-color: #42C6A5;
  color: #ffff;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding-block: .47rem;
  font-family: 'Poppins', sans-serif;
}


.add-to-cart-btn:hover {
  background-color: var(--primary-red);
  color: #E6F6F3;
}

.add-to-cart-btn i {
  font-size: 18px;
}

.text-strike{
  text-decoration: none;
  position: relative;
  font-size: 14rpx;
}
.text-strike::before{
  content: '';
  width: 100%;
  position: absolute;
  right: 0;
  top: calc( 50%  );
  border-bottom: 1px solid var(--primary-red);
}

.product-price {
  color: var(--primary-red);
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 15px;
}

.overflow {
  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}


.bg-faded-warning {
  background-color: rgba(254, 165, 105, 0.09) !important;
}

.bg-faded-info {
  background-color: rgba(105, 179, 254, 0.09) !important;
}

.bg-faded-success {
  background-color: rgba(66, 214, 151, 0.09) !important;
}

.banner-small {

  min-width: 15rem;
  word-break: break-all;
  justify-content: space-around;


}




.banner-small img {}

.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.title a {
  color: rgb(155, 163, 170);
  font-size: .8rem;
  font-weight: 400;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}


.skeleton-banner {
  width: 100%;
  aspect-ratio: 415/190;
}


.skeleton-image {
  height: 200px;
  width: 200;
}

.skeleton-text-sm {
  width: 40%;
  height: 20px;
  border-radius: .2rem !important;
}

.skeleton-text {
  width: 90%;
  height: 20px;
  border-radius: .2rem !important;
}

.skeleton {

  animation: skeleton-loading 1s linear infinite alternate;
  background-size: 200px 100%;

  border-radius: .5rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.quantity-input {
  background-color: #fff;
  width: fit-content;
  border-radius: 2rem;
}

.count-input {
  background-color: rgb(255, 255, 255);
  padding: 0;
  color: rgb(18, 38, 54);
  border: none;
  font-size: 14px;
  outline: none;
  width: 30px;
  text-align: center;
}

.cart-items {
  background-color: rgb(246, 246, 247);
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 1rem;

}

.cart-items img {
  border-radius: 1rem;
}

.quantity-btn {
  border: none;
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  color: rgba(var(--primary-red), 1);

}

.quantity-btn.plus {
  background-color: rgb(18, 38, 54);
  color: #fff;

}

.quantity-btn.minus {
  background-color: rgb(246, 246, 247);
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pay-popup {
  position: fixed;
  width: 100%;
  bottom: 78px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 2;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 30px;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 4px 24px rgba(var(--black), 0.13);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.13);
  justify-content: space-between;
}

.pay-popup .pay-btn {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 20px;
  background-color: rgb(18, 38, 54);
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  border-radius: 8px;
  

}

.disabled {
  pointer-events: auto !important; /* Make the button focusable */
  cursor: not-allowed !important; /* Change the cursor to indicate it's not clickable */
}

.empty-cart {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}




.u-icon {
  width: 6rem;
  height: 6rem;
  font-size: 2.25rem;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #646f79;
  border-color: #646f79;
  z-index: 1;
  line-height: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
}

.empty-cart .btn {
  color: #fff;
  background-color: var(--secondary-green);
  padding: 1rem 2rem;

}


.overflow-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  hyphens: auto;
}


.checkout-wrapper {
  padding: 2rem;
  background-color: var(--grey);
  border-radius: 1rem;
}

.checkout-wrapper .form-control {
  border: none;
}


.icon-btn {
  background-color: var(--secondary-green);
  color: white;
  border: 1px solid var(--secondary-green) !important;
}

.icon-btn:hover {
  background-color: var(--secondary-green);
  color: white;
  border: 1px solid var(--secondary-green) !important;
}

.icon-btn[disabled=disabled],
button:disabled {

  border: none !important;

}

.alert {
  position: fixed;
  right: 1rem;
  top: 1rem;
  padding: .5rem 1rem;
  border-left: var(--primary-red) 2px solid;
  z-index: 20 !important;


}

.alert .close {
  color: var(--primary-red);
}

.light-text {
  color: var(--secondary-body);
}




.cat-container {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 20%;
  padding: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;

}

.cat-container img {
  /* width: 70px;
                height: 70px; */
  width: 100%;
  object-fit: contain;


}

.cat-wrapper {
  width: 70px;
  word-break: break-all;
  line-height: 1.1;

}
.cat-wrapper span{
  font-size: 12px;
}
@media (min-width: 992px){
  .cat-container {
    width: 100px;
    height: 100px;
  
  
  }
  
  .cat-wrapper {
    width: 100px;
  
  }

}


.cat {
  scroll-behavior: smooth;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */


}


.cat::-webkit-scrollbar {
  display: none !important;
  /* Safari and Chrome */
}

.footer-01 {
  background: #0d1824;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.footer {
  padding: 7em 0;
}


.payment-card {
  display: flex;
  border: 1px solid;
  border-color: #dfdada;
  padding: 1rem;
  border-radius: 10px;
}

.payment-card:hover {
  border: 1px solid !important;
  border-color: #dfdada !important;
}

.payment-card .bi {
  color: var(--li-grey);
}

input[type="radio"].btn-check:checked+label.btn.payment-card {
  background-color: #5257f4 !important;
  /* Change the background color as desired */
  border-color: #5257f4;
  color: #fff;

}

input[type="radio"].btn-check:checked+label.btn.payment-card i {
  color: #fff;

}


.fa-circle-notch {


  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.model {
  z-index: 5;
  padding: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  max-width: 500px;


  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.modal-icon i {
  font-size: 5rem;
  color: var(--secondary-green);
  margin-bottom: 2rem;
}

.model-bg {

  background-color: rgba(111, 114, 117, 0.9);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 4;

}

.back-to-top-button {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: var(--secondary-green);
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 999;
  cursor: pointer;
}

.back-to-top-button.visible {
  opacity: 1;
  visibility: visible;
}

.back-to-top-button .bi {
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
}


.whatsapp-button {
  position: fixed;
  right: 16px;
  bottom: 30px; /* Positioned above the back-to-top button */
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #25D366;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
}

.whatsapp-button.visible {
  opacity: 1;
  pointer-events: all;
}

.whatsapp-button:hover {
  background-color: #1da851;
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.whatsapp-button .fa-whatsapp {
  font-size: 24px;
  color: white;
}

footer{
  background-color: #F7FAFC;
}
.newsletter-input{
  background-color: rgba(0, 0, 0, .06);
  border: 0;
  border-radius: 5px;
  padding-inline: 1rem !important;
  font-weight: 300;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: rgb(45, 55, 72);
}
.newsletter-input:focus-visible{
  outline: var(--secondary-green) auto 1px;
}
.newsletter-btn{
  background-color: var(--secondary-green);
}

.newsletter-btn i{
  color: #fff;
}

.footer-socials{
  display: flex;
}
.footer-socials .icon{
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 100%;
  color: #0d1824;
}





.zoom-container {
  position: relative;
  width: 100%; /* Responsive container */
  max-width: 400px; /* Maintain aspect ratio */
  height: 400px; /* Consistent height */
  overflow: hidden;
  margin: auto;
  background-color: #fff; /* Card background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Increased shadow intensity */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.zoom-container:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}

.zoom-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scales image proportionally */
  border-radius: 10px; /* Match container corners */
  transition: transform 0.3s ease; /* Smooth zoom effect */
}

.zoom-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  display: none;
  pointer-events: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6); /* Increased shadow for zoom box */
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%; /* Circular zoom box */
}

@media (max-width: 768px) {
  .zoom-container {
    max-width: 300px;
    height: 300px;
  }
}


